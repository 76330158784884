const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
}

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    products: `${ROOTS.DASHBOARD}/products`,
    reports: `${ROOTS.DASHBOARD}/reports`,
    events: {
      root: `${ROOTS.DASHBOARD}/events`,
      create: `${ROOTS.DASHBOARD}/events/create`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/events/${id}`,
    },
    orders: `${ROOTS.DASHBOARD}/orders`,
    user: `${ROOTS.DASHBOARD}/users`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    transaction: {
      root: `${ROOTS.DASHBOARD}/transaction`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/transaction/${id}`,
    },
  },
}
