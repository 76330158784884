import isEqual from 'lodash/isEqual'
import { useState, useCallback } from 'react'
// @mui
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

// routes
import { paths } from 'src/routes/paths'
import { useParams } from 'src/routes/hook'
import { RouterLink } from 'src/routes/components'

// components
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import Datagrid from 'src/components/datagrid'
import { Helmet } from 'react-helmet-async'
import axiosInstance from 'src/utils/axios'
import { useSnackbar } from 'notistack'
import { useBoolean } from 'src/hooks/use-boolean'
import useTransaction from 'src/service/transaction.service'
import UserTableRow from './table-user/user-table-row'

import UserCreateEditForm from './user-create-edit-view'

const TABLE_HEAD = [
  { id: 'card', label: 'Número do cartão' },
  { id: 'type', label: 'Tipo' },
  { id: 'value', label: 'Valor', width: 180 },
  { id: 'createdAt', label: 'Data e hora' },
  { id: 'event', label: 'Evento' },
  { id: 'order', label: 'Id da compra' },
  { id: '', width: 88 },
]

const defaultPagination = {
  page: 1,
  perPage: 8,
  sort: {
    createdAt: 'desc',
  },
}
const defaultFilters = {
  search: '',
  role: [],
  active: 'all',
}
export default function TransactionListById() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState(defaultFilters)

  const params = useParams()

  const { isLoading, isFetching, data, refetch } = useTransaction(pagination, params.id!, filters)

  const settings = useSettingsContext()

  const { enqueueSnackbar } = useSnackbar()

  const quickEdit = useBoolean()

  const canReset = !isEqual(defaultFilters, filters)

  const notFound = (!data?.data.length && canReset) || !data?.data.length

  const handleDeleteRow = useCallback(
    async (id: string) => {
      try {
        await axiosInstance.delete(`/transaction/${id}`)

        enqueueSnackbar('Transação removida com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover transação !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  return (
    <>
      <Helmet>
        <title>Cashless - Movimentações</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Cashless - Movimentações'
          links={[
            { name: 'Início', href: paths.dashboard.root },
            { name: 'Cashless', href: paths.dashboard.transaction.root },
            { name: 'Movimentações' },
          ]}
          action={
            <Button
              component={RouterLink}
              onClick={() => quickEdit.onTrue()}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Atualizar saldo
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Datagrid
            data={data?.data || []}
            notFound={notFound}
            isLoading={isLoading || isFetching}
            headLabel={TABLE_HEAD}
            refetch={refetch}
            total={data?.total || 0}
            setPagination={setPagination}
            pagination={pagination}
            noSelection
            handleDeleteRows={() => {}}
            ItemContent={UserTableRow}
            handleDeleteRow={handleDeleteRow}
            handleEditRow={() => {}}
          />
        </Card>
      </Container>
      <UserCreateEditForm
        currentUser={{ card: params.id! }}
        onRefleshTable={() => refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  )
}
