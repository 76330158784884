import { useCallback, useEffect, useState } from 'react'
// @mui
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
// types
import { IUserTableFilters, IUserTableFilterValue } from 'src/types/user'
// components
import Iconify from 'src/components/iconify'
import CustomPopover, { usePopover } from 'src/components/custom-popover'
import { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import { ICardTableFilters } from 'src/types/card'

// ----------------------------------------------------------------------

type Props = {
  filters: ICardTableFilters
  onFilters: (name: string, value: IUserTableFilterValue) => void
  roleOptions: OptionType[]
}

export default function UserTableToolbar({ filters, onFilters, roleOptions }: Props) {
  const popover = usePopover()

  const [query, setQuery] = useState(filters.search)
  const [debouncedQuery, setDebouncedQuery] = useState(filters.search)

  const handleFilterName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [query])

  useEffect(() => {
    if (debouncedQuery !== filters.search) {
      onFilters('search', debouncedQuery)
    }
  }, [debouncedQuery, filters.search, onFilters])

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={query}
          onChange={handleFilterName}
          placeholder='Buscar...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  )
}
