// @mui
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'

import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'

import Tooltip from '@mui/material/Tooltip'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// types
import Iconify from 'src/components/iconify'
import { ConfirmDialog } from 'src/components/custom-dialog'

import { ITransactionItem } from 'src/types/transaction'
import { masks } from 'src/utils/masks'
import { format } from 'date-fns'
import Label from 'src/components/label'
import { Box, Link } from '@mui/material'

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: ITransactionItem
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function UserTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { event, type, value, createdAt, card, order } = row

  const confirm = useBoolean()

  const nameTypes = {
    entry: 'Entrada',
    exit: 'Saída',
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{card.card}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label variant='soft' color={type === 'entry' ? 'success' : 'warning'}>
            {nameTypes[type]}
          </Label>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(value)}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{event?.name || 'Sem evento associado'}</TableCell>
        {}
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Tooltip
            title={
              <div>
                {order?.products?.map((p) => (
                  <div>
                    <p>
                      {p.quantity}x {p.product.name}
                      {masks.currencyAllPlatforms(Number(p.quantity) * Number(p.price))}
                    </p>
                  </div>
                ))}
              </div>
            }
            placement='top'
            arrow
          >
            <Label>{order?._id || 'Sem compra'}</Label>
          </Tooltip>
        </TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title='Deletar' placement='top' arrow>
            <IconButton
              onClick={() => {
                confirm.onTrue()
              }}
            >
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Tem certeza que quer deletar?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Deletar
          </Button>
        }
      />
    </>
  )
}
