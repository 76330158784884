// @mui
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'

import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'

import Tooltip from '@mui/material/Tooltip'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// types
import Iconify from 'src/components/iconify'
import { ConfirmDialog } from 'src/components/custom-dialog'

import { ICardItem } from 'src/types/card'

//

// ----------------------------------------------------------------------

export const roleName = {
  admin: 'Administrador',
  user: 'Usuário',
  franchisee: 'Franqueado',
}

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: ICardItem
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function UserTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { card, event } = row

  const confirm = useBoolean()
  const quickEdit = useBoolean()

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{card}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{event?.name || 'Sem evento associado'}</TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title='Consultar movimentações' placement='top' arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={onEditRow}>
              <Iconify icon='solar:eye-bold' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Deletar' placement='top' arrow>
            <IconButton
              onClick={() => {
                confirm.onTrue()
              }}
            >
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Tem certeza que quer deletar?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Deletar
          </Button>
        }
      />
    </>
  )
}
