// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { ICardItem } from 'src/types/card'
import axiosInstance from 'src/utils/axios'

type Cards = {
  data: ICardItem[]
  page: number
  perPage: number
  totalPages: number
  total: number
}

async function fetchUser(pagination: Pagination, filters: any): Promise<Cards> {
  const res = await axiosInstance.get('/card', {
    params: {
      ...filters,
      ...pagination,
    },
  })
  return res.data
}

function useCard(pagination: Pagination, filters?: any) {
  return useQuery(['card', pagination, filters], () => fetchUser(pagination, filters), {
    keepPreviousData: true,
    staleTime: 5000,
  })
}

export default useCard
