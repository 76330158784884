import { Suspense, lazy } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { RoleBasedGuard } from 'src/auth/guard'
// layouts
// components
import { LoadingScreen } from 'src/components/loading-screen'
import DashboardLayout from 'src/layout/dashboard/layout'
import EventCreateEditView from 'src/pages/dashboard/events/events-create-edit-view'
import EventsListView from 'src/pages/dashboard/events/list'
import OrdersListView from 'src/pages/dashboard/orders/list'
import ProductsListView from 'src/pages/dashboard/products/list'
import ReportsListView from 'src/pages/dashboard/reports/list'
import SettingsPage from 'src/pages/dashboard/settings'
import TransactionList from 'src/pages/dashboard/transaction/list'
import TransactionListById from 'src/pages/dashboard/transaction/listTransacions/list'
import UserListView from 'src/pages/dashboard/users/list'

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/home'))

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <RoleBasedGuard hasContent roles={['admin', 'franchisee']}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'settings', element: <SettingsPage /> },
      {
        path: 'users',
        children: [{ element: <UserListView />, index: true }],
      },
      {
        path: 'products',
        children: [{ element: <ProductsListView />, index: true }],
      },
      {
        path: 'events',
        children: [
          {
            element: <EventsListView />,
            index: true,
          },
          { element: <EventCreateEditView />, path: 'create' },
          { element: <EventCreateEditView />, path: ':id' },
        ],
      },
      {
        path: 'orders',
        children: [{ element: <OrdersListView />, index: true }],
      },
      {
        path: 'reports',
        children: [{ element: <ReportsListView />, index: true }],
      },
      {
        path: 'transaction',
        children: [
          {
            element: <TransactionList />,
            index: true,
          },
          { element: <TransactionListById />, path: ':id' },
        ],
      },
    ],
  },
]
