import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { IUserItem } from 'src/types/user'
import { ICardItem } from 'src/types/card'
import { RHFSelect } from 'src/components/hook-form/rhf-select'
import { MenuItem } from '@mui/material'
import { masks } from 'src/utils/masks'
import { ITransactionItem } from 'src/types/transaction'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { card: string }
}

export default function UserCreateEditForm({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const methods = useForm<ITransactionItem>()

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: ITransactionItem) => {
      try {
        await axiosInstance.post('/transaction', {
          ...data,
          value: masks.unmask(data.value),
          card: currentUser?.card!,
        })

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar('Cartão criado com suceso', { variant: 'success' })
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, onRefleshTable, currentUser],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Ajustar saldo do cartão</DialogTitle>

        <DialogContent>
          <Box rowGap={2} columnGap={2} padding={1} display='grid'>
            <RHFTextField name='value' label='Valor' mask='currency' />
            <RHFSelect
              fullWidth
              name='type'
              label='Movimentação'
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value='entry'>Entrada</MenuItem>
              <MenuItem value='exit'>Saida</MenuItem>
            </RHFSelect>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Cadastrar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
