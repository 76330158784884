// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { ITransactionItem } from 'src/types/transaction'
import axiosInstance from 'src/utils/axios'

type Transactions = {
  data: ITransactionItem[]
  page: number
  perPage: number
  totalPages: number
  total: number
}

async function fetchUser(pagination: Pagination, id: string, filters: any): Promise<Transactions> {
  const res = await axiosInstance.get('/transaction', {
    params: {
      ...filters,
      ...pagination,
      card: id,
    },
  })
  return res.data
}

function useTransaction(pagination: Pagination, id: string, filters?: any) {
  return useQuery(
    ['transaction', pagination, filters, id],
    () => fetchUser(pagination, id, filters),
    {
      keepPreviousData: true,
      staleTime: 5000,
    },
  )
}

export default useTransaction
